export const webRoutes = {
    home: 'home',
    dashboard: 'dashboard',
    login: 'login',
    forgotPassword: 'forgot-password',
    profile: 'profile',
    profileNew: 'profilenew',
    pressrelease: 'press-release',
    matchingTest: 'matching-test',
    signup: 'signup',
    showcase: 'showcase',
    contentPartnerships: 'content-partnerships',
    adsync: 'adsync',
    billboards: 'billboards',
    search: 'search',
    create: 'create',
    map: 'map',
    questions: 'questions',
    preregisterMarv: 'form-marv',
    preregisterCo: 'form-co',
    preregister: 'preregister',
    services: 'services',
    contact: 'contact',
    comingSoon: 'coming-soon',
    policy:'policy',
    explore: 'explore',
    marveler: 'dashboard-marveler',
    counselor: 'dashboard-counselor',
    explorer: 'explorer',
    profileMarveler: 'profile-marveler',
    profileCounselor: 'profile-counselor',
    setting: 'setting',
    updatePassword: 'update-password',
    unsubscribe: 'unsubscribe',
    policies: 'policies',
    updateAuth: 'update-auth',
    booking: 'booking',
    availability: 'availability',
    counselorProfile: 'counselor-profile/:id',
    payment: 'payment',
    earning: 'earning',
    counselors: 'counselors',
    reviews: 'reviews',
    editMarveler: 'edit-marveler',
    marvelerInformations: 'marveler-informations',
    editCounselor: 'edit-counselor',
    counselorInformations: 'counselor-informations',
    informationItem: 'information-item',
    resetPassword: 'reset-password',
    register: 'register',
    verify: 'verify',
    gallery: 'gallery',
    checkout: 'checkout'
}